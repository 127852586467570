import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import { Heading04, BodyCopy01 } from '@ux/typography';
import PaddingBox from '@ux/padding-box';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import Illustration from '@ux/illustrations';
import { isNotNilOrEmpty } from 'crosscutting/ramda';
import stylesheet, { Styles } from './Pillar.stylesheet';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import * as rA from 'ramda-adjunct';
import * as r from 'ramda';
import createImageAlt from 'presentation/utils/createImageAlt';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import withLanguage from 'presentation/hocs/withLanguage';
import { Theme } from '@ux/theme-utils';
import { Colorway } from 'domain/constants/colorway';

const hasIllustration = r.propSatisfies(isNotNilOrEmpty, 'illustration');
const useImageUrl = r.both(
  r.propSatisfies(rA.isNilOrEmpty, 'illustration'),
  r.propSatisfies(isNotNilOrEmpty, 'imageUrl'),
);

export interface OuterProps {
  theme: Theme,
  hBaseline: number,
  illustration?: string,
  imageUrl?: string,
  title?: ShortcodeProps,
  body?: ShortcodeProps,
  colorway: Colorway,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
  showImage: boolean,
  showIllustration: boolean,
  currentLanguage: string,
  hasTitle: boolean,
  hasBody: boolean,
}

export const PurePillar = ({
  styles,
  theme,
  hBaseline,
  showIllustration,
  showImage,
  illustration,
  imageUrl,
  title,
  body,
  colorway,
  currentLanguage,
  hasTitle,
  hasBody,
}: InnerProps) => (
  <PaddingBox
    theme={theme}
    style={styles.root}
  >
    <div>
      <If condition={showIllustration}>
        <Illustration
          theme={theme}
          size={styles.illustration.width as number}
          icon={illustration}
        />
      </If>
      <If condition={showImage}>
        <div style={styles.imageContainer}>
          <img
            // @ts-ignore
            loading="lazy"
            src={urlLanguageParse(imageUrl, currentLanguage)}
            alt={createImageAlt({ imageUrl })}
            style={styles.image}
          />
        </div>
      </If>
    </div>
    <div style={styles.content}>
      <If condition={hasTitle}>
        <Heading04
          theme={theme}
          style={styles.title}
          depth={hBaseline}
        >
          <Shortcode
            theme={theme}
            code={title}
            against={colorway}
          />
        </Heading04>
      </If>
      <If condition={hasBody}>
        <BodyCopy01
          theme={theme}
          style={styles.body}
        >
          <Shortcode
            theme={theme}
            code={body}
            against={colorway}
          />
        </BodyCopy01>
      </If>
    </div>
  </PaddingBox>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Pillar', {
    hocs: [
      withLanguage,
    ],
    hooks: [
      withProps((ownerProps: OuterProps) => ({
        showImage: useImageUrl(ownerProps),
        showIllustration: hasIllustration(ownerProps),
        hasTitle: r.propSatisfies(isNotNilOrEmpty, 'title', ownerProps),
        hasBody: r.propSatisfies(isNotNilOrEmpty, 'body', ownerProps),
      })),
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  }

);

export default enhance(PurePillar);
