import {
  compose,
} from 'recompose';
import { connect } from 'react-redux';
import { getCurrentLanguage } from 'domain/selectors/ribbons';

export default (BaseComponent: any) => {
  return compose(
    connect((state) => {
      return {
        currentLanguage: getCurrentLanguage(state),
      };
    }),
  )(BaseComponent);
};
