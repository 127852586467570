import React from 'react';
import * as r from 'ramda';

export default (BaseComponent: any) => r.ifElse(
  r.prop('visible'),
  (props) => <BaseComponent {...props}/>,
  (props) => (
    <div style={{ display: 'none' }}>
      <BaseComponent {...props}/>
    </div>
  ),
);
