import { useStyles } from '@team-griffin/stylr';
import { modifier, percentage } from '@ux/style-utils';
import { gu2px, Curvature } from '@ux/theme-utils';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { InnerProps } from './Pillar';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  content: CSSProperties,
  title: CSSProperties,
  illustration: CSSProperties,
  imageContainer: CSSProperties,
  image: CSSProperties,
  body: CSSProperties,
}

export default ({
  theme,
  theme: {
    spacing,
    curvature,
  },
  isMinSm,
  isMinLg,
  colorway,
}: InnerProps) => useStyles({
  default: {
    root: {
      display: 'flex',
    },
    content: {
      marginLeft: gu2px(3, spacing),
      flex: 1,
      flexBasis: 'auto',
      overflow: 'hidden',
      wordWrap: 'break-word',
    },
    title: {
      color: getTitleColor(theme, colorway),
    },
    illustration: {
      width: gu2px(10, spacing),
    },
    imageContainer: {
      width: gu2px(10, spacing),
      overflow: 'hidden',
    },
    image: {
      height: gu2px(10, spacing),
      position: 'relative',
      left: percentage(50),
      transform: `translateX(-${percentage(50)})`,
    },
    body: {
      color: getBodyColor(theme, colorway),
    },
  },

  isMinSm: {
    illustration: {
      width: gu2px(14, spacing),
    },
    imageContainer: {
      width: gu2px(14, spacing),
      overflow: 'hidden',
    },
    image: {
      height: gu2px(14, spacing),
    },
  },

  isMinLg: {
    illustration: {
      width: gu2px(16, spacing),
    },
    imageContainer: {
      width: gu2px(16, spacing),
      overflow: 'hidden',
    },
    image: {
      height: gu2px(16, spacing),
    },
    content: {
      marginLeft: gu2px(4, spacing),
    },
  },

  [modifier('curvature', Curvature.WEAK)]: {
    imageInner: {
      borderRadius: 3,
    },
  },

  [modifier('curvature', Curvature.STRONG)]: {
    imageInner: {
      borderRadius: 8,
    },
  },
}, {
  curvature,
  isMinSm,
  isMinLg,
});
