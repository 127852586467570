/*
  createImageAlt({
    illustration: ?String,
    imageUrl: String,
  });

  Returns a relevant alt tag text from the URL path
*/

import { isNotNilOrEmpty } from '@team-griffin/capra';
import * as r from 'ramda';

const hasImage = r.propSatisfies(isNotNilOrEmpty, 'imageUrl');

/*
  getImageName
  From a URL like https://img1.wsimg.com/cdn/Image/All/Website/2/en-GB/ad586f1e-81a0-4a9d-a675-e266e25e965f/Christian_Jaroljmek.jpg
  return the text between the final slash and dot (Christian_Jaroljmek)

  If no regex match, return the string as is (this will capture illustrations)
*/
const getImageName = (str: string) => {
  if (typeof str !== 'string') {
    return '';
  }

  const image = str.match(/\/([^/]+?)\.[a-zA-Z0-9]+$/);

  if (image && image[1]) {
    return image[1];
  }

  return str;
};

/*
  formatName
  Replace any underscores and hyphens with whitespace
*/
const formatName = (str: string) => str.replace(/[-_]/g, ' ');

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  getImageName,
  formatName,
};

export default r.ifElse(
  hasImage,
  r.pipe(
    r.prop('imageUrl'),
    getImageName,
    formatName,
  ),
  r.always(''),
);