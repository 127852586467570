export default (url: string, language: string) => {
  if (url && url.length && language) {
    let parsedLanguage;
    if (language.split('-').length === 2) {
      parsedLanguage = [
        language.split('-')[0],
        language.split('-')[1].toUpperCase(),
      ].join('-');
    } else {
      parsedLanguage = language;
    }
    return url.replace('{{language}}', parsedLanguage);
  }
  return url;
};