import React from 'react';
import {
  defaultProps,
  withProps,
} from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import Ribbon from 'components/ribbons/Ribbon';
import Pillar from './Pillar';
import { Container } from '@ux/grid';
import visibilitySet from 'presentation/hocs/visibilitySet';
import errorBoundary from 'presentation/hocs/errorBoundary';
import stylesheet, { Styles } from './PillarsRecumbent.stylesheet';
import * as r from 'ramda';
import { BreakpointProps, withBreakpointsHook } from '@ux/responsive';
import { RibbonProps } from 'domain/constants/ribbons';
import { ShortcodeProps } from 'presentation/components/Shortcode';

type PillarProps = {
  illustration?: string,
  imageUrl?: string,
  title?: ShortcodeProps,
  body?:ShortcodeProps,
  preserveDimension?: string,
};

interface OuterProps extends RibbonProps {
  pillars: PillarProps[],
}
interface DefaultProps extends OuterProps {
  hBaseline: number,
}
export interface InnerProps extends DefaultProps, BreakpointProps {
  styles: Styles,
}

declare const i: number;
declare const pillar: PillarProps;

export const PurePillarsRecumbent = ({
  id,
  hBaseline,
  styles,
  theme,
  layout,
  pillars,
  colorway,
  loading,
  name,
}: InnerProps) => (
  <Ribbon
    name={name}
    theme={theme}
    colorway={colorway}
    layout={layout}
    loading={loading}
    id={id}
    type="PillarsRecumbent"
  >
    <Container>
      <div style={styles.grid}>
        <For
          each="pillar"
          of={pillars}
          index="i"
        >
          <Pillar
            key={i}
            hBaseline={hBaseline}
            theme={theme}
            colorway={colorway}
            {...r.pick([
              'title',
              'body',
              'illustration',
              'imageUrl',
            ])(pillar)}
          />
        </For>
      </div>
    </Container>
  </Ribbon>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'PillarsRecumbent', {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      defaultProps({
        hBaseline: 3,
      }),
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  },
);

export default enhance(PurePillarsRecumbent);
