import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { gu2px } from '@ux/theme-utils';
import { InnerProps } from './PillarsRecumbent';
import { CSSProperties } from 'react';

export interface Styles {
  grid: CSSProperties,
}
export default ({
  theme: {
    spacing,
  },
  isMinSm,
  isMinMd,
}: InnerProps) => useStyles({
  default: {
    grid: {
      display: 'grid',
      gridTemplateColumns: percentage(100),
      gridColumnGap: gu2px(2, spacing),
      gridRowGap: gu2px(1, spacing),
    },
  },

  isMinSm: {
    grid: {
      gridRowGap: gu2px(2, spacing),
    },
  },

  isMinMd: {
    grid: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}, {
  isMinSm,
  isMinMd,
});
